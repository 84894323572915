/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './CarouselPhotos.scss';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class CarouselPhotos extends Component {

  scrollToAuctionsList = () => {
    document.getElementById('auctions-list').scrollIntoView();
  }

  render() {
    return (
      <Carousel indicators={false} className='carousel-home'>
        {this.props.photos.map((photo) => (
          <Carousel.Item interval={5000}>
            {photo.id_remate !== null ? (
              <Link to={`/subastas/${photo.id_remate}`}>
                <img
                  className='d-block w-100'
                  src={`${Constants.BASE_URL}${photo.image}`}
                />
              </Link>
            ) : photo.is_principal ? (
              this.props.sessionProps.account === null ?
                <Link to={`/ingresar`}>
                  <img
                    className='d-block w-100'
                    src={`${Constants.BASE_URL}${photo.image}`}
                  />
                  <div className='details-for-banner'>
                    <div>
                      {
                        window.socket_io_customer === 'subastas_ya' || window.socket_io_customer === 'remates_morales' || window.socket_io_customer === 'rey_subastas' ?
                          <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_admin} />
                          :
                          <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_web} />
                      }
                      <label className='text-white text-center'>{photo.text_principal}</label>
                      <button className='primary-background primary-color rounded-pill font-weight-bold'>Ingresar</button>
                    </div>
                  </div>
                </Link>
                :
                <>
                  <img
                    className='d-block w-100'
                    src={`${Constants.BASE_URL}${photo.image}`}
                  />
                  <div className='details-for-banner'>
                    <div>
                      {
                        window.socket_io_customer === 'subastas_ya' || window.socket_io_customer === 'remates_morales' ?
                          <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_admin} />
                          :
                          <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_web} />
                      }
                      <label className='text-white text-center'>{photo.text_principal}</label>
                      <button onClick={() => this.scrollToAuctionsList()}
                        className='primary-background primary-color rounded-pill font-weight-bold'>Participar</button>
                    </div>
                  </div>
                </>
            ) : (
              <img
                className='d-block w-100'
                src={`${Constants.BASE_URL}${photo.image}`}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  accountStore: state.accountReducer,
});

export default connect(mapStateToProps, null)(CarouselPhotos);