import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import './Login.scss';
import { userLogin, userLoginFB, userLoginWithPin } from '../services/UserServices';
import { connect } from 'react-redux';
import { userSetStorageAction } from '../redux/actions/UserActions';
import Loading from '../components/loading/Loading';
import ForgetPassword from '../components/modals/ForgetPassword';

let title = 'Ingresar'

const Login = (props) => {
    const { y, segment, auction } = props.match?.params || {}
    const { configAccount, setLocalStorage, isInHome, history } = props

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [pin, setPin] = useState('')
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)
    const [showForgetPassword, setShowForgetPassword] = useState(false)

    const isFacebookApp = () => {
        const ua = navigator.userAgent || navigator.vendor || window.opera;
        return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
        if (isFacebookApp()) {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
    }, [configAccount])

    const handleChange = event => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value.replace(/\s+/g, ''))
        }
        if (name === 'password') {
            setPassword(value)
        }
        if (name === 'phone') {
            setPhone(value)
        }
        if (name === 'pin') {
            setPin(value)
        }
    }

    const login = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            let response = null
            if (configAccount.config?.simply_auth) {
                response = await userLoginWithPin({
                    phone,
                    pin
                });
            } else {
                response = await userLogin({
                    email,
                    password
                });
            }
            setLoading(false);
            if (auction && y && segment) {
                history.push(`/${segment}/${auction}/${y}`);
            }
            setLocalStorage(response.data.data);
        } catch (error) {
            console.log(error)
            setLoading(false);
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            setMessageError(msg);
        }
    }

    const loginFB = async (data) => {
        setLoading(true);
        try {
            let response = await userLoginFB({
                email: data.email,
                fbId: data.id,
                name: data.name,
                json: data
            });
            setLoading(false);
            if (auction && y && segment) {
                history.push(`/${segment}/${auction}/${y}`);
            }
            setLocalStorage(response.data.data);
        } catch (error) {
            setLoading(false);
            setMessageError(error.response.data.message);
        }
    }

    const closeModal = async () => {
        setShowForgetPassword(false);
    }

    const responseFacebook = (response) => {
        loginFB(response);
    }

    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordShown(isPasswordShown => !isPasswordShown);
    };

    return (
        <React.Fragment>
            {showForgetPassword && <ForgetPassword close={() => closeModal()} />}
            {loading && <Loading />}
            {
                !isInHome ? (
                    <>
                        <br /><br />
                    </>
                ) : null
            }
            <section className={`login-background ${!isInHome ? 'mt-5' : ''}`}>
                <div className={`container login-area pt-5 ${!isInHome ? 'mt-4' : ''}`}>
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="col-12 col-md-5 mb-5">
                            <div className="login-content">
                                <div className="text-center d-flex flex-column">
                                    <h1 className='font-weight-bold text-primary-background'>
                                        Iniciar sesión
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <form className="login-form" onSubmit={login}>
                                {
                                    !configAccount.config?.simply_auth ?
                                        <>
                                            <div className="form-group">
                                                <input type="email" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill mb-4"
                                                    placeholder='&#xf0e0;&nbsp;&nbsp;Email' name="email"
                                                    value={email} onChange={handleChange} required />
                                            </div>

                                            <div className="form-group" style={{ position: 'relative' }}>
                                                <input type={isPasswordShown ? "text" : "password"} style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill" placeholder="&#xf084;&nbsp;&nbsp;Contraseña" name="password"
                                                    value={password} onChange={handleChange} required />
                                                <button type='button' onClick={togglePasswordVisibility} className="toggle-password">
                                                    {isPasswordShown ? 'Ocultar' : 'Mostrar'}
                                                </button>
                                            </div>

                                            <div className="col-12 d-flex justify-content-between mb-4 align-items-rigth">
                                                <span style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setShowForgetPassword(true)} className="forgot-password mt-0 font-weight-bold">
                                                    <u>¿Olvidó su contraseña?</u>
                                                </span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="form-group">
                                                <input type="text" autoComplete='off' style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill"
                                                    placeholder='&#xf095;&nbsp;&nbsp;Celular' name="phone"
                                                    value={phone} onChange={handleChange} required />
                                            </div>

                                            <div className="form-group">
                                                <input type="password" autoComplete='off' style={{ fontFamily: "FontAwesome", fontSize: '18px' }} className="form-control rounded-pill" placeholder="&#xf084;&nbsp;&nbsp;Pin" name="pin" maxLength={4}
                                                    value={pin} onChange={handleChange} required />
                                            </div>
                                        </>
                                }

                                <button type="submit" className="col-12 text-white btn text-capitalize rounded-pill primary-background">Iniciar sesión</button>

                                {
                                    messageError ? (
                                        <div style={{ textAlign: 'center', color: 'red', marginTop: '0.5rem' }}>
                                            <label>{messageError}</label>
                                        </div>
                                    ) : null
                                }

                                {
                                    window.socket_io_customer !== 'remates_morales' ?
                                        <div style={{ textAlign: 'center', width: '100%', marginTop: '0.5rem' }}>
                                            {
                                                configAccount.config?.facebook_app_id &&
                                                <FacebookLogin
                                                    appId={configAccount.config?.facebook_app_id}
                                                    autoLoad={false}
                                                    fields="name,email"
                                                    callback={responseFacebook}
                                                    cssClass="facebook-button-class"
                                                    icon="fa-facebook"
                                                    textButton="Ingresar con Facebook"
                                                    disableMobileRedirect={true} />
                                            }
                                        </div> : null
                                }

                                <div className='col-12 mt-5 mb-5 text-center text-black font-weight-bold'>
                                    ¿Aún no tienes cuenta? &nbsp;
                                    <Link className='text-primary-background' style={{ fontSize: '17px' }} to="/registrar">REGISTRATE AQUI</Link>
                                </div>



                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {
                !isInHome ? (
                    <>
                        <br /><br />
                    </>
                ) : null
            }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
