import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ItemBuySell from '../../components/item_buy_sell/Item_buy_sell';
import { ContactUs } from '../../components/home/ContactUs';

let title = 'Vender';

const items = {
    0: [
        {
            title: 'Crearte un usuario',
            description: 'Dirígite a la opción Iniciar sesión y selecciona REGÍSTRATE AQUÍ. En esta sección se te pedirán algunos datos personales y tendrás que crear una contraseña personal que te permitirá entrar regularmente a tu cuenta.'
        },
        {
            title: 'Realiza una subasta',
            description: 'Una vez activa una subasta podrás encontrar objetos de tu interés y comenzar a generar tus ofertas. El mejor postor al momento de finalizar la subasta será el comprador de dicho lote.'
        },
    ],
    1: [
        {
            title: 'Cierre de oferta por lote',
            description: 'Al iniciar el proceso de cierre en la hora indicada, comenzarán a cerrarse un conjunto de lotes cada 3 minutos y así sucesivamente hasta finalizar la totalidad de los lotes publicados.'
        },
        {
            title: 'Excepción de cierre',
            description: 'Cuando los lotes estén por cerrarse, la cuenta regresiva determina el cierre, salvo si hay una oferta en el último minuto de dicha cuenta regresiva, se entenderá el tiempo por un minuto más.'
        }
    ]
}

const Buy = ({ configAccount }) => {

    useEffect(() => {
        if (configAccount.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
    }, [configAccount])

    /*
    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        try {
            await this.setState({ loading: true });
            let response = await getPageBuy();
            let { head, items } = response.data.data;
            await this.setState({
                head: head.dynamic_seccion,
                items: items,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }
    */

    return (
        <React.Fragment>
            <section className="faq-area ptb-60">
                <div className="container section buy">
                    <div className='mb-5 d-flex flex-row title-buy-sell'>
                        <span className='rounded-pill border-title-third' ></span>
                        <h1 className='primary-background font-weight-bold ml-3'>PREGUNTAS&nbsp;</h1>
                        <h1 className='font-weight-bold'>DE VENTA</h1>
                    </div>
                    <h4 className="font-weight-bold" >¿Como participar en nuestras subastas online?</h4>
                    <div className='row mb-5 justify-content-between'>
                        {
                            items[0]?.map((item, index) => <ItemBuySell description={item.description} title={item.title} number={index + 1} key={index} />)
                        }
                    </div>
                    <br /><br />
                    <h4 className="font-weight-bold" >¿Como participar en nuestras subastas online?</h4>
                    <div className='row mb-5 justify-content-between'>
                        {
                            items[1]?.map((item, index) => <ItemBuySell description={item.description} title={item.title} number={index + 1} key={index} />)
                        }
                    </div>
                    <br /><br />
                    <h4 className="font-weight-bold" >¿Como participar en nuestras subastas online?</h4>
                    <div className='row mb-5 justify-content-between'>
                        {
                            items[0]?.map((item, index) => <ItemBuySell description={item.description} title={item.title} number={index + 1} key={index} />)
                        }
                    </div>
                    <br /><br />
                    {
                        /*
                            <div dangerouslySetInnerHTML={{ __html: this.state.head }} className="description-inner-html" />
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <Accordion allowMultipleExpanded={true}>
                                        {
                                            this.state.items.map(item =>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {item.title}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            )
                                        }
                                    </Accordion>
                                </ul>
                            </div>
                        */
                    }
                </div>
                <ContactUs />
            </section>
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
});

export default connect(mapStateToProps, null)(Buy);