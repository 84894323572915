import React, { useEffect, useState } from 'react';
import './ValidateWpp.scss';
import Loading from '../loading/Loading';
import { updateMyAccount } from '../../services/UserServices';
import { toast } from 'react-toastify';
import { userSetStorageAction } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import axios from 'axios';
import { Constants } from '../../Constants';

const validate = async (data) => {
    try {
        const httpOptions = {
            headers: {
                Authorization: data.token,
            },
        };
        return await axios.post(`${Constants.BASE_URL}myaccount/validate-wpp`, data, httpOptions);
    } catch (error) {
        throw error;
    }
};

const resendValidation = async (data) => {
    try {
        const httpOptions = {
            headers: {
                Authorization: data.token,
            },
        };
        return await axios.post(`${Constants.BASE_URL}myaccount/resend-validation-wpp`, data, httpOptions);
    } catch (error) {
        throw error;
    }
};

const ValidateWpp = ({ sessionProps, logout, history, setLocalStorage }) => {

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        code: '',
        phone: sessionProps.account.phone || '',
    });

    useEffect(() => {
        toast.configure();
    }, [])

    const handleChange = event => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    }

    const validateWpp = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = {
            token: sessionProps.account.token,
            code: form.code,
        };
        try {
            let response = await validate(data);
            toast("Su número fue validado con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            setLocalStorage(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response?.status === 401) {
                logout();
                history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const resendValidationWpp = async () => {
        setLoading(true);
        let data = {
            token: sessionProps.account.token,
            phone: form.phone,
        };
        try {
            let response = await resendValidation(data);
            toast("Su número fue validado con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            setLocalStorage(response.data.data);
            localStorage.setItem('rv.google', 1);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response?.status === 401) {
                logout();
                history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    return (
        <React.Fragment>
            <div className="container-modal">
                <div className="content-modal">
                    {loading && <Loading />}
                    <h5 style={{ marginBottom: '1rem' }}>Para continuar ingrese el código que recibió en su Whastapp</h5>
                    <form className="signup-form" onSubmit={validateWpp}>
                        <div className="form-group">
                            <label>Código <b style={{ color: 'red' }}>*</b></label>
                            <input type="text" className="form-control" placeholder="Ingrese el código" name="code"
                                value={form.code} onChange={handleChange} required />
                        </div>
                        <button type="submit" className="btn button-with-degrade w-100 primary-background text-white rounded-pill">Confirmar</button>
                    </form>
                    <div className="form-group mt-4">
                        <label>¿No recibió el código? Verifique su número</label>
                        <div style={{ display: 'flex' }}>
                            <input type="text" className="form-control" placeholder="Verificar número de whatsapp" name="phone"
                                value={form.phone} onChange={handleChange} />
                            <button onClick={resendValidationWpp} className="btn button-with-degrade primary-background text-white" style={{ marginLeft: '5px' }} disabled={
                                localStorage.getItem('rv.google') === '1'
                            }>
                                Reenviar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
});


const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateWpp);