import React, { useEffect, useState } from 'react';
import './styles.scss';
import BannerSlider from '../../components/home/BannerSlider';
import ItemAuction from './item-auction';
import { useAuction } from '../../store/auction';
import { Constants } from '../../Constants';
import moment from 'moment';
import { connect } from 'react-redux';
import Login from '../Login';
import groupImg from '../../assets/images/group.png';
import { userLoginWithCode } from '../../services/UserServices';
import { userSetStorageAction } from '../../redux/actions/UserActions';
import { toast } from 'react-toastify'
import { ContactUs } from '../../components/home/ContactUs';
import Loading from '../../components/loading/Loading';
import GoogleMap from '../../components/google-maps/GoogleMap';

const Home = (props) => {
  const { accountProps } = props
  toast.configure();
  const { sessionProps, setLocalStorage } = props;
  const auctionStore = useAuction();
  const [intervalId, setIntervalId] = useState(null);
  const [loginCode, setLoginCode] = useState('');
  const [loading, setLoading] = useState(false);

  const [auctionLists, setAuctionLists] = useState({
    inProgress: [],
    completed: [],
    pending: [],
    presencials: []
  });

  useEffect(() => {
    document.title = 'Inicio'
  }, [])

  useEffect(() => {
    if (!intervalId) {
      auctionStore.getAllAuctions();
      let id = setInterval(() => {
        auctionStore.getAllAuctions();
      }, 60000);
      setIntervalId(id);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (accountProps?.config?.title_tabs) {
      document.title = accountProps.config.title_tabs + ' - ' + document.title;
    }
  }, [accountProps])

  useEffect(() => {
    if (null != auctionStore.auctions) {
      setAuctionLists({
        ...auctionLists,
        inProgress: auctionStore.auctions.data?.inProgress || [],
        completed: auctionStore.auctions.data?.completed || [],
        pending: auctionStore.auctions.data?.pending || [],
        presencials: auctionStore.auctions.data?.presencials || []
      });
    }
  }, [auctionStore.auctions]);

  useEffect(() => {
  }, [auctionLists]);

  const handleChange = event => {
    const { value } = event.target;
    setLoginCode(value);
  }

  const loginWithCode = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await userLoginWithCode(loginCode);
      setLoading(false);
      setLocalStorage(response.data.data);
    } catch (error) {
      setLoading(false);
      let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
      if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
        msg = error.response.data.message;
      }
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
        duration: 10000
      });
    }
  }

  let isWhiteBackground = true;
  return (
    <div className='home-box'>
      <BannerSlider />
      {loading && <Loading />}

      {/*
        sessionProps.account !== null ?
          <div className='container' style={{ marginTop: '4rem' }}>
            <img src={thanks} alt="" style={{ width: '100%' }} />
          </div>
          : null*/
      }

      {
        window.socket_io_customer === 'de_remate_ofertas' ? (
          <div className="col-12 p-0 map-without-shipping">
            <h4>¡Atención! No realizamos envíos de mercadería, los lotes deben ser retirados en nuestro local</h4>
            {
              accountProps.config?.contact_map_lat &&
              <GoogleMap
                lat={accountProps.config.contact_map_lat}
                lng={accountProps.config.contact_map_lng}
              />
            }
          </div>
        ) : null
      }

      <section className='light' id="auctions-list">
        {0 < auctionLists.inProgress.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? 'background-white' : ''}`}>
            <div className={`box container `}>
              <div className='mb-5 d-flex'>
                <span className='rounded-pill border-title text-primary-background' ></span>
                <div className='col-12'>
                  <h1 className='text-primary-background font-weight-bold'>SUBASTAS</h1>
                  <h3 className='text-black font-weight-bold'>EN PROCESO</h3>
                </div>
              </div>
              <div className='row'>
                {auctionLists.inProgress.map((item) => (
                  <div key={item.id} className='col-12 col-md-4 mt-4 d-flex'>
                    <ItemAuction
                      name={item.title}
                      auctionNumber={item.id}
                      dateFrom={`${moment(item.date_from).format(
                        'dddd D'
                      )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                        item.date_from
                      ).format('HH:mm')}h.`}
                      dateTo={`${moment(item.date_to).format(
                        'dddd D'
                      )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                        item.date_to
                      ).format('HH:mm')}h.`}
                      img={`${Constants.BASE_URL}${item.image}`}
                      imgMobile={item.imageMobile ? `${Constants.BASE_URL}${item.imageMobile}` : null}
                      lotsCount={item.count_lot}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.inProgress.length ? isWhiteBackground = !isWhiteBackground : null}

        {0 < auctionLists.presencials.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? 'background-white' : ''}`}>
            <div className={`box container `}>
              <div className='mb-5 d-flex'>
                <span className='rounded-pill border-title text-primary-background' ></span>
                <div className='col-12 ml-1'>
                  <h1 className='text-primary-background font-weight-bold'>SUBASTAS</h1>
                  <h3 className='text-black font-weight-bold'>PRESENCIALES</h3>
                </div>
              </div>
              <div className='row'>
                {auctionLists.presencials.map((item) => (
                  <div key={item.id} className='col-12 col-md-4 mt-4 d-flex'>
                    <ItemAuction
                      name={item.title}
                      presencial={true}
                      auctionNumber={item.id}
                      dateFrom={item.date_from ? `${moment(item.date_from).format(
                        'dddd D'
                      )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                        item.date_from
                      ).format('HH:mm')}h.` : 'Próximamente'}
                      dateTo={`${moment(item.date_to).format(
                        'dddd D'
                      )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                        item.date_to
                      ).format('HH:mm')}h.`}
                      img={`${Constants.BASE_URL}${item.image}`}
                      lotsCount={item.count_lot}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.presencials.length ? isWhiteBackground = !isWhiteBackground : null}

        {0 < auctionLists.pending.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? 'background-white' : ''}`}>
            <div className={`box container `}>
              <div className='mb-5 d-flex'>
                <span className='rounded-pill border-title text-primary-background'></span>
                <div className='col-12 ml-1'>
                  <h1 className='text-primary-background font-weight-bold'>SUBASTAS</h1>
                  <h3 className='text-black font-weight-bold'>POR INICIAR</h3>
                </div>
              </div>
              <div className='row'>
                {auctionLists.pending.map((item) => (
                  <div className='col-md-4 col-sm-12 mt-4 d-flex'>
                    <ItemAuction
                      name={item.title}
                      auctionNumber={item.id}
                      dateFrom={`${moment(item.date_from).format(
                        'dddd D'
                      )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                        item.date_from
                      ).format('HH:mm')}h.`}
                      img={`${Constants.BASE_URL}${item.image}`}
                      imgMobile={item.imageMobile ? `${Constants.BASE_URL}${item.imageMobile}` : null}
                      lotsCount={item.count_lot}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.pending.length ? isWhiteBackground = !isWhiteBackground : null}

        {0 < auctionLists.completed.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? 'background-white' : ''}`}>
            <div className='box container'>
              <div className='mb-5 d-flex pt-5'>
                <span className='rounded-pill border-title text-primary-background' ></span>
                <div className='col-12 ml-1'>
                  <h1 className='text-primary-background font-weight-bold'>SUBASTAS</h1>
                  <h3 className='text-black font-weight-bold'>FINALIZADAS</h3>
                </div>
              </div>

              <div className='row'>
                {auctionLists.completed.map((item) => (
                  <div key={item.id} className='col-md-4 col-12 mb-5 d-flex'>
                    <ItemAuction
                      name={item.title}
                      auctionNumber={item.id}
                      dateTo={`${moment(item.date_to).format(
                        'dddd D'
                      )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                        item.date_to
                      ).format('HH:mm')}h.`}
                      img={`${Constants.BASE_URL}${item.image}`}
                      imgMobile={item.imageMobile ? `${Constants.BASE_URL}${item.imageMobile}` : null}
                      lotsCount={item.count_lot}
                      type='column'
                      finished
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.completed.length ? isWhiteBackground = !isWhiteBackground : null}

        {
          sessionProps.account === null &&
          <div className={`pt-5 pb-5 ${isWhiteBackground ? 'background-white' : ''}`}>
            <div id="access_code" className={`container align-items-center`}>
              <div className="row text-center">
                <div className='col-md-5 col-12'>
                  <img className='w-100' src={groupImg} alt="imagen group"></img>
                </div>
                <div className='col-md-1 col-12'></div>
                <div className='col-md-4 col-12 d-flex justify-content-center align-items-center background-white'>
                  <span style={{ borderRadius: '4%' }} className='text-black '>
                    <p style={{ width: '100%', marginBottom: '0.2rem', fontSize: '18px', fontWeight: 'bold' }}>
                      Si estas registrado y tienes inconvenientes para ingresar, solicite un código de acceso directo
                    </p>
                    <br />
                    <p className='mb-4 font-weight-bold'>
                      ¿Ya tienes un código de acceso?
                    </p>
                    <input className='form-control rounded-pill mb-3' style={{ width: '100%', textAlign: 'center' }} placeholder="Ingrese su código de acceso" name="loginCode"
                      value={loginCode} onChange={handleChange} required />
                    <button type="button" onClick={loginWithCode} style={{ width: '100%', marginTop: '0.5rem' }} className="text-white primary-background btn rounded-pill font-weight-bold">Ingresar</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
        {sessionProps.account === null ? isWhiteBackground = !isWhiteBackground : null}
      </section>

      <ContactUs />

      <section className='light'>
        {
          sessionProps.account === null ? (
            <div className='box container'>
              <div className='row'>
                <div className='col-12'>
                  <Login props={props} isInHome />
                </div>
              </div>
            </div>
          ) : null
        }
      </section >
    </div >
  );
};


const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  accountProps: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setLocalStorage: (data) => {
    dispatch(userSetStorageAction(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
